import Chart from 'chart.js';
const mixin = {
	props: {
		chartData: {
			type: Object,
			default: null,
		},
		options: {
			type: Object,
			default: null,
		},
	},
	mounted() {
		this.renderChart(this.chartData, this.options);
	},
	methods: {
		textCenter(val) {
			Chart.pluginService.register({
				beforeDraw: function (chart) {
					var width = chart.chart.width;
					var height = chart.chart.height;
					var ctx = chart.chart.ctx;

					ctx.restore();
					var fontSize = (height / 114).toFixed(2);
					ctx.font = fontSize + 'em sans-serif';
					ctx.textBaseline = 'middle';

					var text = val;
					var textX = Math.round((width - ctx.measureText(text).width) / 2);
					var textY = height / 2;

					ctx.fillText(text, textX, textY);
					ctx.save();
				},
			});

			Chart.plugins.unregister(this.chartdata);
		},
	},
};
export default mixin;
